import React, { useState, useEffect } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Button,
  IconButton,
  Paper,
  Box,
  Card,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import moment from "moment";

import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import useStyles from "./styles";
import Table from "../../components/Table/Table";
import Form from "./components/Form";
import { getCertificateData } from "./SettingState";
import { getFile } from "../leads/LeadState";
import InvoicePDF from "../leads/components/ViewLead/invoice/InvoicePDF";

export default function Settings() {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [responseData, setResponseData] = useState([]);
  // eslint-disable-next-line
  const [selectedItem, setSelectedItem] = useState([]);
  const [certUrl, setCertUrl] = useState("");
  var loggedInDatas = useSelector((state) => state.userdata);
  const header = [
    t("customer_company_name"),
    t("common_certificate_number"),
    t("common_certificate_valid_date"),
    t("common_status"),
    t("common_reason"),
    t("common_pollution_certificate"),
  ];

  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: t("cust_administration"), link: "/#/app/admin" },
    { label: t("Company Documents"), link: "" },
  ];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlepdf = (item) => {
    setSelectedItem(item);
    let body = {
      fileName: item.fileName,
      folderName: item.folderName,
      expireLimt: 1800,
    };
    getFile(body).then((response) => {
      setCertUrl(response.reference);
      setShowPDF(true);
    });
  };

  const handleshowhidepdf = () => {
    setShowPDF(!showPDF);
  };
  const handleClose = () => {
    setShowForm(!showForm);
    callData();
  };

  const callData = () => {
    if (loggedInDatas && loggedInDatas.getData) {
      let bodyData = {
        tenID: loggedInDatas.getData.customerid,
      };
      let data = [];
      getCertificateData(bodyData, dispatch).then((resp) => {
        if (resp.status === "200" && resp.tenantData !== null) {
          setResponseData(resp.tenantData);
          if (resp.tenantData.certificate) {
            // eslint-disable-next-line
            resp.tenantData.certificate.map((item) => {
              data.push([
                resp.tenantData.name,
                item.certNumber,
                moment(item.lastDate).format("DD-MM-YYYY"),
                item.status === ""
                  ? "Awaiting Approval"
                  : item.status === true
                    ? "Approved"
                    : "Rejected",
                item.reason,
                <IconButton
                  className={classes.collapseIcon}
                  onClick={() => handlepdf(item)}
                  size="large"
                >
                  <PictureAsPdfIcon />
                </IconButton>,
              ]);
            });
            setTableData(data);
          }
        }
      });
    }
  };

  useEffect(() => {
    callData();
    // eslint-disable-next-line
  }, []);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        <Card>
          <AppBar position="static" sx={{ bgcolor: "white" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
            >
              <Tab label={"Certificates"} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            {showPDF ? (
              <>
                <Grid container spacing={2}>
                  <Grid
                    item
                    lg={10}
                    md={11}
                    sm={12}
                    xs={12}
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleshowhidepdf}
                    >
                      {t("common_back")}
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={2} md={2} xs={1} sm={1}></Grid>
                  <Grid item lg={8} md={8} xs={10} sm={10}>
                    <InvoicePDF url={certUrl} close={handleshowhidepdf} />
                  </Grid>
                  <Grid item lg={2} md={2} xs={1} sm={1}></Grid>
                </Grid>
              </>
            ) : showForm ? (
              <>
                <Grid container spacing={2}>
                  <Grid
                    item
                    lg={10}
                    md={11}
                    sm={12}
                    xs={12}
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                    >
                      {t("common_back")}
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={2} md={2} xs={1} sm={1}></Grid>
                  <Grid item lg={8} md={8} xs={10} sm={10}>
                    <Paper elevation={3}>
                      <Form close={handleClose} />
                    </Paper>
                  </Grid>
                  <Grid item lg={2} md={2} xs={1} sm={1}></Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container spacing={3}>
                  <Grid item lg={2} md={2} xs={12} sm={12}></Grid>
                  <Grid item lg={8} md={8} xs={12} sm={12}>
                    <Paper elevation={3} className={classes.certText}>
                      <h4>
                        Place to upload all Binbag Certificates.
                        <br />
                        Note: Your complete Access will be be blocked once your
                        certificate expires.
                      </h4>
                    </Paper>
                  </Grid>
                  <Grid item lg={2} md={2} xs={12} sm={12}></Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid
                    item
                    lg={11}
                    md={11}
                    sm={12}
                    xs={12}
                    style={{ textAlign: "right", margin: "10px 0" }}
                  >
                    {responseData.certificateStatus === "Rejected" ||
                    responseData.certificateStatus === "None" ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                      >
                        {t("common_add_certificate")}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={8} xs={12} sm={12}>
                    <Table
                      data={tableData}
                      header={header}
                      name=""
                      options={{
                        filterType: "checkbox",
                        print: false,
                        download: false,
                        filter: false,
                        selectableRows: "none",
                      }}
                    />
                  </Grid>
                  <Grid item lg={1} md={2} xs={0} sm={0}></Grid>
                </Grid>
              </>
            )}
          </TabPanel>
        </Card>
      </div>
    </>
  );
}
