// validationSchema.js
import * as yup from "yup";

const eighteenYearsAgo = new Date();
eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

export const contactSchema = yup.object().shape({
  fullName: yup
    .string()
    .required("Full name is required")
    .min(2, "Name must be at least 2 characters"),

  mobileNumber: yup
    .string()
    .required("Mobile number is required")
    .matches(/^\d{10}$/, "Please enter a valid 10-digit phone number"),

  countryCode: yup.string().required("Country code is required"),

  email: yup
    .string()
    .required("Email is required")
    .email("Please enter a valid email"),

  dateOfJoiningBinbag: yup
    .date()
    .nullable()
    .test(
      "is-employee",
      "Date of joining is required for employees",
      function (value) {
        const isEmployee = localStorage.getItem("type") === "tenant";
        if (isEmployee) {
          return !!value;
        }
        return true;
      }
    ),
  dateOfBirth: yup
    .date()
    .nullable()
    .test(
      "is-employee",
      "Date of birth is required for employees",
      function (value) {
        const isEmployee = localStorage.getItem("type") === "tenant";
        if (isEmployee) {
          return !!value;
        }
        return true;
      }
    ),
  designation: yup.string().required("Designation is required"),
  isTemparory: yup
    .boolean()
    .required("Temporary status is required")
    .default(false),

  isFactoryEmployee: yup
    .boolean()
    .required("Factory employee status is required")
    .default(false),
  department: yup
    .array()
    .min(1, "Select at least one department")
    .required("Departments are required"),
});
