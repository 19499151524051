import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getData } from "../LoginContainer/LoginState";
import { getCertificateData } from "../setting/SettingState";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Snackbar,
  Badge,
  Chip,
} from "@mui/material";

import { useStyles } from "./styles";
import CelebrationAlerts from "./components/CelebrationAlerts";
import { getEmployeeAnniversayNotifications } from "./apis";
import { ShoppingCart } from "@mui/icons-material";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AodOutlinedIcon from "@mui/icons-material/AodOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import WebOutlinedIcon from "@mui/icons-material/WebOutlined";
// import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";

const DashboardItem = ({ title, link, icon: Icon, tabs, badge, tags }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(link, { tabs });
  };

  return (
    <div className={classes.cardWrapper}>
      {badge && (
        <div className={classes.badge}>
          <Badge
            badgeContent={badge}
            color="primary"
            className={classes.customBadge}
            max={999}
          />
        </div>
      )}
      <Card
        className={classes.card}
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        <CardContent className={classes.cardContent}>
          <Icon className={classes.icon} />
          <Typography variant="h6" component="h2" gutterBottom>
            {title}
          </Typography>
          {tags && tags.length > 0 && (
            <div className={classes.chipContainer}>
              {tags.map((tag, index) => (
                <Chip key={index} label={tag} size="small" />
              ))}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [celebrations, setCelebrations] = useState([]);

  const data = useSelector((state) => state.userdata);

  const getAnniversaryNotifications = async () => {
    try {
      const response = await getEmployeeAnniversayNotifications({
        userId: data?.getData?._id,
      });

      if (response.status === "200" && data.length !== 0) {
        setCelebrations(response.data);
      }
    } catch (error) {
      console.error("Error fetching anniversary notifications:", error);
    }
  };

  useEffect(() => {
    getAnniversaryNotifications();
  }, [data]);

  const userType = data.getData
    ? data.getData.type
    : localStorage.getItem("type");
  const custid = data.getData
    ? data.getData.customerid
    : localStorage.getItem("customerid");

  const getUserType = (userData) => {
    if (userData?.type === "tenant") {
      return userData.roles?.includes("Admin") ? "tenantAdmin" : "tenantUser";
    }
    return userData?.type === "customer" ? "customer" : "customer";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getData(dispatch);
        if (userType === "tenant" && !data.getData?.roles?.includes("user")) {
          const bodyData = { tenID: custid };
          const resp = await getCertificateData(bodyData, dispatch);
          if (resp.status === "200" && resp.tenantData) {
            if (!resp.tenantData.isVerified) {
              setError("Please add Pollution Certificate");
            }
            if (!resp.tenantData.isValid) {
              setError(
                "Your Pollution Certificate is Expired! Please contact Admin!"
              );
            }
          }
        }
      } catch (err) {
        setError("An error occurred while fetching data");
      }
    };

    fetchData();
  }, []);

  const dashboardItems = {
    customer: [
      {
        title: "MPR",
        link: "/app/MPR",
        icon: ShoppingCart,
      },
      {
        title: "Administration",
        link: "/app/admin",
        icon: AdminPanelSettingsOutlinedIcon,
      },
    ],
    tenantAdmin: [
      {
        title: "Payments",
        link: "/app/payments",
        icon: AccountBalanceWalletOutlinedIcon,
        tags: [
          "Supplier",
          "Vendor",
          "Reimbursements",
          "Miscellaneous",
          "Demand Draft",
        ],
      },

      {
        title: "Leads",
        link: "/app/leads",
        icon: QueryStatsOutlinedIcon,
        tags: ["MPRs", "Inspection", "Quotation", "Invoices"],
      },
      {
        title: "Inquiries",
        link: "/app/inquiries",
        icon: AssignmentOutlinedIcon,
        tags: ["Website", "Demo Request", "Emails"],
      },
      {
        title: "Customers",
        link: "/app/customers",
        icon: DomainAddOutlinedIcon,
        tags: ["Active", "Inactive", "Potential"],
      },
      {
        title: "Website leads",
        link: "/app/webLeads",
        icon: WebOutlinedIcon,
        tags: ["Company profile"],
      },
      {
        title: "Marketplace",
        link: "/app/marketplace",
        icon: AodOutlinedIcon,
        tags: ["Products", "Listings", "Bids"],
      },
      {
        title: "Buyers",
        link: "/app/mpUsers",
        icon: GroupsOutlinedIcon,
        tags: ["Potential", "New", "Existing"],
      },
      {
        title: "Administration",
        link: "/app/admin",
        icon: AdminPanelSettingsOutlinedIcon,
        tags: ["Employees", "Documents"],
      },
      {
        title: "Employee portal",
        link: "/app/employeePortal",
        icon: BadgeOutlinedIcon,
        tags: ["Leaves", "Your Documents"],
      },
      // {
      //   title: "Events",
      //   link: "/app/events",
      //   icon: CalendarMonthOutlinedIcon,
      //   badge: "Coming Soon",
      //   tags: ["Upcoming", "Past", "Planning", "Inspection slots", "Tenders"],
      // },
    ],
    tenantUser: [
      {
        title: "Payments",
        link: "/app/payments",
        icon: AccountBalanceWalletOutlinedIcon,
        tags: [
          "Supplier",
          "Vendor",
          "Reimbursements",
          "Miscellaneous",
          "Demand Draft",
        ],
      },
      {
        title: "Leads",
        link: "/app/leads",
        icon: QueryStatsOutlinedIcon,
        tags: ["MPRs", "Assigned", "Inspection", "Quotation", "Invoices"],
      },
      {
        title: "Inquiries",
        link: "/app/inquiries",
        icon: AssignmentOutlinedIcon,
        tags: ["Website", "Demo Request", "Assigned", "Emails"],
      },
      {
        title: "Customers",
        link: "/app/customers",
        icon: DomainAddOutlinedIcon,
        tags: ["Active", "Inactive", "Potential"],
      },
      {
        title: "Employee portal",
        link: "/app/employeePortal",
        icon: BadgeOutlinedIcon,
        tags: ["Leaves", "Your Documents"],
      },
      {
        title: "Marketplace",
        link: "/app/marketplace",
        icon: AodOutlinedIcon,
        tags: ["Products", "Listings", "Bids"],
      },
      // {
      //   title: "Events",
      //   link: "/app/events",
      //   icon: CalendarMonthOutlinedIcon,
      //   badge: "Coming Soon",
      //   tags: ["Upcoming", "Past", "Planning", "Inspection slots", "Tenders"],
      // },
    ],
  };

  const filteredItems =
    dashboardItems[getUserType(data.getData)]?.filter((item) => {
      const itemMatchesSearch = item.title.toLowerCase();
      return itemMatchesSearch;
    }) || [];

  return (
    <div className={classes.root} style={{ marginTop: "70px" }}>
      <CelebrationAlerts
        celebrations={celebrations}
        setCelebrations={setCelebrations}
      />
      <Grid container spacing={{ xs: 2, sm: 3, md: 4, lg: 5 }}>
        {filteredItems.map((item, index) => (
          <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
            <DashboardItem {...item} badge={item.badge} />
          </Grid>
        ))}
      </Grid>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </div>
  );
}
