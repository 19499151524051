import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  CircularProgress,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ContactForm from "./ContactForm";
import EmployeeForm from "./EmployeeForm";
import { addEmployee } from "../ContactState";
import { createMprId } from "../../material_pickup_request/MprState";
import { contactSchema } from "./../../../schemas/validationSchema";
import useStyles from "../styles";
import toast, { Toaster } from "react-hot-toast";
import EmployeeDocuments from "./EmployeeDocuments";

const Forms = ({ where, close }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const custData = useSelector((state) => state);
  const state = useSelector((state) => state.userdata);

  const [showLoader, setShowLoader] = useState(false);

  const isEmployee =
    localStorage.getItem("type") === "tenant" &&
    location.pathname === "/app/addEmployee";

  const defaultValues = {
    fullName: custData.selectedUser.userName || "",
    mobileNumber: custData.selectedUser.mobile?.number || "",
    countryCode: custData.selectedUser.mobile?.code || "+91",
    email: custData.selectedUser.email || "",
    dateOfJoiningBinbag:
      custData.selectedUser?.employeeData?.dateOfJoiningBinbag || null,
    dateOfBirth: custData.selectedUser?.employeeData?.dateOfBirth
      ? custData.selectedUser.employeeData?.dateOfBirth
      : null,
    isTemparory: custData.selectedUser?.isTemparory || false,
    isFactoryEmployee:
      custData.selectedUser?.employeeData?.isFactoryEmployee || false,
    department: custData.selectedUser?.department,
    designation: custData.selectedUser?.employeeData?.designation,
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(contactSchema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    setShowLoader(true);
    try {
      const formData = {
        fullName: data.fullName,
        mobileNumber: data.mobileNumber,
        countryCode: data.countryCode,
        email: data.email,
        customerId:
          localStorage.getItem("type") === "Customer"
            ? localStorage.getItem("customerid")
            : custData.selectedCustomer._id,
        role:
          localStorage.getItem("type") === "tenant" ? "user" : "Branch_Admin",
        branch:
          state.getData?.thisCustomerBranch || custData?.selectedBranch?._id,
        userId: custData.selectedUser._id || "",
        EditUserId: state.getData._id,
        department: data.department,
        isBinbagEmployee: isEmployee || false,
        employeeData: {
          dateOfJoiningBinbag: data.dateOfJoiningBinbag?.toISOString(),
          dateOfBirth: data.dateOfBirth?.toISOString(),
          hasLeftBinbag: false,
          isTemparory: data.isTemparory,
          isFactoryEmployee: data.isFactoryEmployee,
          designation: data.designation,
        },
      };

      const response = await addEmployee(formData, dispatch);

      if (response.status === "200") {
        toast.success(response.message);
        const mprData = {
          abbrevation:
            localStorage.getItem("type") === "Customer"
              ? state.getData.abbrevation
              : custData.selectedCustomer.abbrevation,
          customerid: formData.customerId,
          thisCustomerBranch: formData.branch,
        };

        await createMprId(mprData, dispatch);

        setTimeout(() => {
          setShowLoader(false);
          close && close();
        }, 2000);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
      setShowLoader(false);
    }
  };

  const handleBack = () => {
    dispatch({ type: "SET_SELECTED_USER", payload: [] });
    if (localStorage.getItem("type") === "Customer") {
      history.push("/app/customerBranch");
    } else {
      history.push("/app/employees");
    }
  };

  return (
    <Paper sx={{ p: 4 }} elevation={0}>
      <Toaster />
      <Typography variant="h4" mb={3}>
        Employee details
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <ContactForm control={control} errors={errors} classes={classes} />

        <Box mt={3}>
          <EmployeeForm
            control={control}
            errors={errors}
            isDisabled={custData.selectedUser?.employeeData?.isFactoryEmployee}
          />
        </Box>

        <Box mt={3}>
          <EmployeeDocuments documents={custData?.selectedUser?.employeeData} />
        </Box>

        <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
          {where === "page" ? <Button onClick={handleBack}>Back</Button> : null}

          {/* {where === "modal" ? (
            <Button variant="outlined" onClick={close}>
              Back
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={() => reset(defaultValues)}
              disabled={showLoader}
            >
              Reset
            </Button>
          )} */}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={showLoader}
          >
            {showLoader ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default Forms;
