import { apiUrl } from "../../data/config";

export function addContact(data, dispatch) {
  let bodyData = {};
  let url = "";
  if (data.userId === "") {
    url = apiUrl + "/v1/customer/newUser";
    let mobile = {
      code: data.countryCode,
      number: data.mobileNumber,
    };
    bodyData = {
      users: {
        userName: data.fullName,
        branchId: data.branch,
        referenceId: localStorage.getItem("customerid"),
        email: data.email,
        mobile: mobile,
        createdAt: new Date().toISOString(),
        createdBy: "System",
        updatedBy: "System",
        isActive: true,
        roles: [data.role],
        isPrimary: true,
        department: data.department,
        password: "",
        isTemperory: true,
        type: localStorage.getItem("type"),
        employeeData: data.employeeData,
        isBinbagEmployee: data.isBinbagEmployee,
      },
      customer: data.customerId,
      branch: data.branch,
    };
  } else if (data.userId !== "") {
    url = apiUrl + "/v1/customer/updateDetail";
    bodyData = {
      updateItem: "contact",
      updateid: data.userId,
      updateData: {
        userName: data.fullName,
        email: data.email,
        mobile: { code: data.countryCode, number: data.mobileNumber },
        updatedBy: data.EditUserId,
        employeeData: data?.employeeData,
      },
    };
  }

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
      "ngrok-skip-browser-warning": true,
    },
    body: JSON.stringify(bodyData),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "401") {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      } else {
        return data;
      }
    });
  });
}

export function deleteContact(id) {
  let url = apiUrl + "/v1/customer/deletedetails";
  let bodyData = {
    deleteItem: "contact",
    deleteid: id,
  };
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
      "ngrok-skip-browser-warning": true,
    },
    body: JSON.stringify(bodyData),
  }).then((response) => {
    return response.json().then((data) => {
      return data;
    });
  });
}

export function updateContact(data, updatedRole, dispatch) {
  const url = apiUrl + "/v1/updateEmployee";
  const bodyData = {
    userId: data.contactId,
    updateData: {
      updatedBy: data.updatedById,
      roles: updatedRole,
    },
  };
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
      "ngrok-skip-browser-warning": true,
    },
    body: JSON.stringify(bodyData),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "401") {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      } else {
        return data;
      }
    });
  });
}

export async function addEmployee(data, dispatch) {

  let bodyData = {};
  let url = "";
  if (data.userId === "") {
    url = apiUrl + "/v1/addEmployee";
    let mobile = {
      code: data.countryCode,
      number: data.mobileNumber,
    };
    bodyData = {
      users: {
        userName: data.fullName,
        branchId: data.branch,
        referenceId: localStorage.getItem("customerid"),
        email: data.email,
        mobile: mobile,
        createdAt: new Date().toISOString(),
        createdBy: "System",
        updatedBy: "System",
        isActive: true,
        roles: [data.role],
        isPrimary: true,
        department: data.department,
        password: "",
        isTemperory: true,
        type: localStorage.getItem("type"),
        employeeData: data.employeeData,
        isBinbagEmployee: data.isBinbagEmployee,
      },
      customer: data.customerId,
      branch: data.branch,
    };
  } else if (data.userId !== "") {
    url = apiUrl + "/v1/updateEmployee";
    bodyData = {
      userId: data.userId,
      updateData: {
        userName: data.fullName,
        email: data.email,
        mobile: { code: data.countryCode, number: data.mobileNumber },
        updatedBy: data.EditUserId,
        employeeData: data?.employeeData,
        department: data.department,
      },
    };
  }

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
      "ngrok-skip-browser-warning": true,
    },
    body: JSON.stringify(bodyData),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "401") {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      } else {
        return data;
      }
    });
  });
}

export async function getEmployees(data) {
  let url = apiUrl + "/v1/getAllEmployees";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
      "ngrok-skip-browser-warning": true,
    },
    body: JSON.stringify(data),
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return response;
    }
  });
}
